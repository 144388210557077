import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope,} from '@fortawesome/free-regular-svg-icons';
import {Container ,Row,Col,} from "react-bootstrap";
import lg1_key from "../../assets/images/lg1.png";
import lg2_key from "../../assets/images/lg2.png";
import lg3_key from "../../assets/images/lg3.png";
import lg4_key from "../../assets/images/lg4.png";
import lg5_key from "../../assets/images/lg5.png";
import lg6_key from "../../assets/images/lg6.png";
import lg7_key from "../../assets/images/lg7.png";
import lg8_key from "../../assets/images/lg8.png";
import lg1hover_key from "../../assets/images/lg1hover.png";
import lg2hover_key from "../../assets/images/lg2hover.png";
import lg3hover_key from "../../assets/images/lg3hover.png";
import lg4hover_key from "../../assets/images/lg4hover.png";
import lg5hover_key from "../../assets/images/lg5hover.png";
import lg6hover_key from "../../assets/images/lg6hover.png";
import lg7hover_key from "../../assets/images/lg7hover.png";
import lg8hover_key from "../../assets/images/lg8hover.png";
import { Link } from "react-router-dom";
import "./Whatyougetwithcurehotline.css";

function Whatyougetwithcurehotline() {
  return (
    <div className="whatyouget_holder">
      <Container>
        <h2 className='whatyou_htag'>What you get with Living Links</h2>
        <div className="p_tagparent">
              
        </div>
        <Row>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg1_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg1hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Exclusive Property Listings</h2>
              <p className='logos_ptag'>Access to a wide range of rental properties across the UK, from cozy flats to spacious family homes, including exclusive listings you won’t find anywhere else.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg2_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg2hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Personalized Property Recommendations</h2>
              <p className='logos_ptag'>Our platform uses intelligent algorithms to recommend properties based on your preferences, saving you time in your search.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg3_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg3hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Transparent Rental Process</h2>
              <p className='logos_ptag'>We ensure a hassle-free rental experience with clear, upfront information on rental terms, fees, and property conditions.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg4_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg4hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Verified Landlords and Properties</h2>
              <p className='logos_ptag'>We prioritize your safety and peace of mind by thoroughly vetting all landlords and property listings on our site.</p>
            </div>
          </Col>

          {/* /// */}
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg5_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg5hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Efficient Booking & Viewing Scheduling</h2>
              <p className='logos_ptag'>Easily book viewings and schedule appointments at your convenience through our intuitive platform.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg6_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg6hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Virtual Tours & 3D Walkthroughs</h2>
              <p className='logos_ptag'>Explore properties from the comfort of your home with our virtual tours and 3D walkthroughs, providing a realistic view of the space.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg7_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg7hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Responsive Customer Support</h2>
              <p className='logos_ptag'>Our dedicated support team is available to assist you with any questions or issues throughout your rental journey.</p>
            </div>
          </Col>
          <Col md={3} sm={6}>
            <div className="whatyouget_logos_detail_holder position-relative">
              <div className="lg_main_hplder">
                <div className="lg1_hold">
                  <img class="img-fluid" src={lg8_key} alt="" />
                </div>
                <div className="lg1_hold2">
                  <img class="img-fluid" src={lg8hover_key} alt="" />
                </div>
              </div>
              <h2 className='onlin_htag'>Community Insights</h2>
              <p className='logos_ptag'>Get valuable insights into local neighborhoods, including amenities, schools, and transportation options, helping you choose the perfect location.</p>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default Whatyougetwithcurehotline