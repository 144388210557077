import React from 'react'
import {Container ,Row,Col, Navbar, Nav,} from "react-bootstrap";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import "./Aboutmapmaker.css";
function Aboutmapmaker() {
  return (
    <div className="aboutmapmaker_hold">
        <Container>
            <Row>
                <Col sm={6}>
                <div className="mapmaker_head">
                    <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe class="gmap_iframe"
                                width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                                src="https://maps.google.com/maps?width=420&amp;height=420&amp;hl=en&amp;q=GANGL Dienstleistungen - Microsoft Outlook,
                                Exchange, Office 365 und MS Teams Spezialist&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                </iframe><a href="https://embedmapgenerator.com/">embed google maps in website</a>
                            </div>
                    </div>
                </div>
                </Col>
                <Col sm={6}>
                    <div className="lotifile_holder">
                        <Player 
                        autoplay
                        loop
                        src="https://lottie.host/32581096-8734-4536-b67e-1f59423b4b84/Oz7N03dLbh.json"
                        // style={{ height: '300px', width: '300px' }}
                        >
                        {/* <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                        </Player>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Aboutmapmaker