import {BrowserRouter as Router,} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './Components/Home/Home'
import Aboutus from './Components/Aboutus/Aboutus'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/aboutus" element={<Aboutus/>} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
