import React from 'react'
import {Container ,Row,Col, Navbar, Nav, Button,} from "react-bootstrap";
import { Form } from "react-bootstrap";
import arham_logo_key from "../../assets/images/arham_logo.png";
import location_key from "../../assets/images/location_logo.png";
import hover_location_key from "../../assets/images/hover_location.png";
import phone_key from "../../assets/images/phone.png";
import hover_phone_key from "../../assets/images/hover_phone.png";
import email_key from "../../assets/images/logo_email.png";
import hover_email_key from "../../assets/images/hover_email.png";
import google_key from "../../assets/images/google.png";
import h_google_key from "../../assets/images/h_google.png";
import linkedin_key from "../../assets/images/linkedin.png";
import h_linkedin_key from "../../assets/images/h_linkedin.png";
import facebook_key from "../../assets/images/facebook.png";
import h_facebook_key from "../../assets/images/h_facebook.png";
import twitter_key from "../../assets/images/twitter.png";
import h_twitter_key from "../../assets/images/h_twitter.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer() {
    const phoneNumber = "+447399153094"; // Replace with your actual phone number
  const defaultMessage = "Hello, I need assistance!"; // Default message

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(defaultMessage)}`;

  return (
   <div id="footer">
        <div className="top_footer">
            <Container>
                <h3 className='footer_heading'>Keep in Tuch</h3>
                <p className='foot_quality_ptag'>Quality Healthcare Starts with Quality doctors.Let our  online practitioners five you the support you need.  </p>
                <div className="hole_maker">
                    <Row>
                        <Col sm={6}>
                            <div className="footer_left_inputarea">
                                    <Form className="requestinformation-form">
                                        <Form.Group  controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text"className='requestinformation-input-area'  placeholder="Name" />
                                        </Form.Group>
                                        <Form.Group  controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" className='requestinformation-input-area' placeholder="Email" />
                                        </Form.Group>
                                        <Form.Group  controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" className='requestinformation-input-area' placeholder="Subject" />
                                        </Form.Group>
                                        <Form.Group  controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" className='requestinformation-text-area'placeholder="Message" />
                                        </Form.Group>
                                        <button className='btn_talk_to_d'>Submit</button>
                                    </Form>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="full_roght">
                                <div className="foot_right_data">
                                    <div className="head_linkcontent">
                                        <div className="img_logo">
                                            <Link to="/" className='foot_link'><div className="f_img_hold"> <img class="img-fluid" src={location_key} alt="" /></div><div className="f_img_hold2"> <img class="img-fluid" src={hover_location_key} alt="" /></div></Link>
                                        </div>
                                        <div className="footright_content_foot"><Link to='/' className='foot_link'><span>Location</span></Link>
                                           <p className='foot_ptag'>19 Cardinal street, Manchester, England</p>
                                        </div>
                                    </div>
                                    <div className="head_linkcontent">
                                        <div className="img_logo">
                                            <Link to="/" className='foot_link'><div className="f_img_hold"> <img class="img-fluid" src={phone_key} alt="" /></div><div className="f_img_hold2"> <img class="img-fluid" src={hover_phone_key} alt="" /></div></Link>
                                        </div>
                                        <div className="footright_content_foot"><Link to='/' className='foot_link'><span>Contact</span></Link>
                                        <Link to="/" className='contact_num'>+92 34 59666526</Link>
                                        <Link to="/" className='contact_num2'>+021 6808888</Link>
                                        </div>
                                    </div>
                                    <div className="head_linkcontent">
                                        <div className="img_logo">
                                            <Link to="/" className='foot_link'><div className="f_img_hold"> <img class="img-fluid" src={email_key} alt="" /></div><div className="f_img_hold2"> <img class="img-fluid" src={hover_email_key} alt="" /></div></Link>
                                        </div>
                                        <div className="footright_content_foot"><Link to='/' className='foot_link'><span>Email</span></Link>
                                        <div className="contact_email_holder">
                                            <Link to="/" className='contact_email'>info@livinglinks.com</Link>
                                        </div>
                                        </div>
                                    </div>
                                  
                                    <ul>
                                        <li><Link to={whatsappLink} target="_blank" rel="noopener noreferrer" className='links_ui_stuckture' ><span className="fb_icon"><FontAwesomeIcon icon={faFacebookF} /></span></Link></li>
                                        <li><Link to="/" className='links_ui_stuckture' ><div className="img_icon_holder"><img class="img-fluid" src={twitter_key} alt="" /></div><div className="img_icon_holder2"><img class="img-fluid" src={h_twitter_key} alt="" /></div></Link></li>
                                        <li><Link to="/" className='links_ui_stuckture' ><div className="img_icon_holder"><img class="img-fluid" src={google_key} alt="" /></div><div className="img_icon_holder2"><img class="img-fluid" src={h_google_key} alt="" /></div></Link></li>
                                        <li><Link to="/" className='links_ui_stuckture' ><div className="img_icon_holder"><img class="img-fluid" src={linkedin_key} alt="" /></div><div className="img_icon_holder2"><img class="img-fluid" src={h_linkedin_key} alt="" /></div></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="background">
                    </div>
                </div>

            </Container>
        </div>
        <div className="bottom_foot position-relative">
            <Container>
                    <div className="foot_bottom_hold">
                        <p>Copyright 2019 <span className='c_style'>c</span> Living Links  All Rights Reserved</p>
                        {/* <div className="main_arhamlogo_hold_img">
                            <div className="arham_logoholder"> 
                                <img class="img-fluid" src={arham_logo_key} alt="" />
                            </div>
                        </div> */}
                    </div>
            </Container>
        </div>
   </div>
  )
}

export default Footer