import React from 'react'
import Header  from '../Header/Header'
import Banner from '../Banner/Banner'
import Curehotline_content from '../Curehotline_content/Curehotline_content'
import Whatyougetwithcurehotline from '../Whatyougetwithcurehotline/Whatyougetwithcurehotline'
import Generalphysicians from '../Generalphysicians/Generalphysicians'
import Whatwetreatslider from '../Whatwetreatslider/Whatwetreatslider'
import Ourexperts from '../Ourexperts/Ourexperts'
import Footer from '../Footer/Footer'
import Footer2 from '../Footer2/Footer2'
function Home() {
  return (
    <>
      <Header/>
      <Banner/>
      <Curehotline_content/>
      <Whatyougetwithcurehotline/>
      <Generalphysicians/>
      {/* <Whatwetreatslider/> */}
      <Ourexperts/>
      <Footer2/>
      {/* <Footer/> */}
    </>
  )
}

export default Home