import React from 'react'
import Slider from "react-slick"; 
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight,faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import "./Aboutslider.css";
function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<button className="slick-arrow slick-next" onClick={onClick}>
			<FontAwesomeIcon icon={faAngleRight} />
		</button>
	);
}
function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<button className="slick-arrow slick-prev" onClick={onClick}>
			<FontAwesomeIcon icon={faAngleLeft} />
		</button>
	);
}
function Aboutslider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay:true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
    };
  return (
    <div className="about_slide_holder">
        <Container>
            <Slider {...settings}>
                    <div className="about_slider_content_holder">
                        <h2 className='h_tag_aboutslider'>what he said</h2>
                        <p className='p_tag_aboutslider'> The secret of health for both mind and body 
                        is not to murn for the past, nor to worry about the fiture,but to live the
                        present moment wisely and earnestly</p>
                        <h2 className='bottom_h_tag_aboutslider' >- gautama buddha</h2>
                    </div>
                    <div className="about_slider_content_holder">
                        <h2 className='h_tag_aboutslider'>a nice quote</h2>
                        <p className='p_tag_aboutslider'>I,ve learned that people will forget what you said,
                        people forget what u did ,but people will never forget how you made them feel.</p>
                        <h2 className='bottom_h_tag_aboutslider' >- maya angelou</h2>
                    </div>
                    <div className="about_slider_content_holder">
                    <div className="about_slider_content_holder">
                        <h2 className='h_tag_aboutslider'>another quote</h2>
                        <p className='p_tag_aboutslider'>When each day is the same as the next, it,s besause 
                        people fail to recognize the good things that happen in thire lives every
                        that the sun rises</p>
                        <h2 className='bottom_h_tag_aboutslider' >- paulo coelho</h2>
                    </div>
                    </div>
            </Slider>
         </Container>
    </div>
  )
}

export default Aboutslider