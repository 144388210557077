
import React, { useState, useEffect, } from 'react'
import "./Aboutcountingnum.css";
const Countingprops =(props)=> {
  const [ count, setcount ] = useState(0)
  useEffect(() => {
      if(count < props.countValue ){
          setTimeout(()=> {
              setcount( prevVal => prevVal+1)
          },7)
      }
  }, [count])
  
  useEffect(()=> {
    if(props.inView){
      setcount(0)
    }
  }, [props.inView])

  return (
    <div className="progresbar_props_holder"  >
    
            <div className='count_holder'>
                <span className='countTag'>{`${count}+`}</span>
            </div>
    </div>
  )
}

export default Countingprops