import React from 'react'
import {Container ,Row,Col, Navbar, Nav,} from "react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faArrowRightLong,faCompactDisc,faCartShopping,faArrowUp} from '@fortawesome/free-solid-svg-icons';
// import onclikph_key from "../../assets/images/onclick_ph.png";
// import greenimg_key from "../../assets/images/greenimg.png";
// import onclick_bottom_img_key from "../../assets/images/onclick_bottom_img.jpg";
// import { Link } from "react-router-dom";
import "./Aboushowtheworld.css";
function Aboushowtheworld() {
  return (
    <div className="aboushowtheworld_holder">
        <Container>
            <div className="aboushowtheworld_hold">
                <h2 className='h_tag_showtheworld'> show the world with you do </h2>
                <p className='p_tag_showtheworld'>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                sed diam nomummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wosi enim ad minim veniam</p>
            </div>
        </Container>
        {/* <div className="aboutonclick_holder">
          <div className="aboutonclick_hold">
              <Link to="/" className='onclick_link'><span className="disc_icon"><FontAwesomeIcon icon={faCompactDisc} /></span>related</Link>
              <Link to="/" className='onclick_link2'><span className="cardshoping_icon"><FontAwesomeIcon icon={faCartShopping} /></span>buy now</Link>
          </div>
          <div className="onclick_page_show">
            <div className="heading_onclick">
              <span className="disc2_icon"><FontAwesomeIcon icon={faCompactDisc} /></span>
              <h2 className='interactive'>Qode Interactive</h2>
            </div>
            <h2 className='relatedthems'>related themes</h2>
            <div className="onclick_ing_hold">
            <img class="img-fluid" src={onclikph_key} alt="" />
            </div>
            <h2 className='ametrine'>ametrine</h2>
            <div className="creative_cls">
              <h2>creative business</h2>
              <span>$85</span>
            </div>
            <div className="green_ing_hold">
            <img class="img-fluid" src={greenimg_key} alt="" />
            </div>
            <h2 className='ametrine'>Aethos</h2>
            <div className="creative_cls">
              <h2>creative business</h2>
              <span>$79</span>
            </div>
            <div className="green_ing_hold">
               <img class="img-fluid" src={onclick_bottom_img_key} alt="" />
            </div>
            <div className="check">
            <Link to="/" className='onclick_bottom_link'>view all qode themes<span className="arrowup_icon"><FontAwesomeIcon icon={faArrowUp}/></span></Link>

            </div>
          </div>
    </div> */}
    </div>
  )
}

export default Aboushowtheworld