import React from 'react'
import {Container ,Row,Col, } from "react-bootstrap";
import doctor_key from "../../assets/images/banner.jpg";
import Accordion from 'react-bootstrap/Accordion';
// import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import "./Ourexperts.css";

function Ourexperts() {
  return (
    <div className="ourexperts_holder">
        <Container>
            <Row>
                <Col md={5} className='d-flex align-items-center justify-content-center'>
                    <div className="ourexperts_leftside">
                        <div className="doctor_hold">
                            <img className="img-fluid" src={doctor_key} alt="" />
                        </div>
                    </div>
                </Col>
                <Col md={7}>
                    <div className="ourexpers_rightside">
                        <h2 className='ourexpert_htag'>our experts always ready <br></br>for your help <span className='Lets_clear'>Lets clear those doubts  </span> </h2>
                        <div className="accodion_holder">
                        <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What documents do I need to rent a property?</Accordion.Header>
                            <Accordion.Body>
                            Typically, you will need to provide proof of identity, proof of income, and references from previous landlords. The specific requirements may vary depending on the property and landlord.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Are the properties on Living Links verified? </Accordion.Header>
                            <Accordion.Body>
                            Yes, all our properties go through a strict verification process to ensure the listings are genuine and accurate. We work closely with landlords to maintain the quality and reliability of our platform.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header> Can I negotiate the rent with the landlord?</Accordion.Header>
                            <Accordion.Body>
                            While the rent is usually set by the landlord, some are open to negotiation based on the rental term, tenant profile, and other factors. It’s best to discuss directly with the landlord or through our platform’s secure messaging system.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What if I face issues with the property after moving in?</Accordion.Header>
                            <Accordion.Body>
                            If you encounter any issues, you can report them directly to the landlord through our platform. Our support team is also available to assist with any disputes or concerns.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How do I pay the rent?</Accordion.Header>
                            <Accordion.Body>
                            Rent payments are typically made directly to the landlord, but some properties offer the option to pay through our platform for added convenience and security.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Can I get a virtual tour before booking a viewing?</Accordion.Header>
                            <Accordion.Body>
                            Absolutely! Most of our properties offer virtual tours and high-quality images to help you get a feel for the space before scheduling an in-person viewing.
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                        
                        </div>
                        <div className="b-holder">
                            <button to="/" className="b-slide-down">
                                <span className="b-name">Still Confused. Contact Us</span>
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
  )
}

export default Ourexperts