import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import slid_img1_key from "../../assets/images/h1.jpg";
import slid_img2_key from "../../assets/images/h2.jpg";
import slid_img3_key from "../../assets/images/h3.jpg";
import slid_img4_key from "../../assets/images/h4.jpg";
import slid_img5_key from "../../assets/images/h5.jpg";
import slid_img6_key from "../../assets/images/h6.jpg";
import yello from '../../assets/images/yellowLogo.jpg'
import trending_headimg from "../../assets/images/head_img.jpg";

import Slider from "react-slick";
import Propsgeneralphysicians from "./Propsgeneralphysicians";
import "./Generalphysicians.css";

function Generalphysicians() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
     autoplay:true,
    slidesToScroll: 1,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }
  ]
  };
  return (
    <div className="generalphysicians_holder">
      <div className="blue_background"></div>
      <div className="slid_hole">
        <Container>
          <div className="headings d-flex justify-content-center text-center">
            <div>
            <h2 className="General_Physicians_htag tex-center">
            Featured new-build developments for sale
          </h2>
          <p className="Our_certified_ptag">
            Finding the perfect home for you. Expert guidance every step of the
            way. Let us make your renting journey easy.
          </p>
            </div>
         
          </div>
          <div className="trendingnftslider_holder">
            <Container>
              <Slider {...settings}>
                <Propsgeneralphysicians
                  img={slid_img1_key}
                  title="Lazy Cat"
                  btnclick="Sandikasiltawa"
                  yellowimg={yello}
                  count="4/75"
                  ruppies="price"
                  ethvalue="12ETH"
                  highestbid="highest Bid"
                  ethvalue2="4.3ETH"
                  imgsml={trending_headimg}
                />
                <Propsgeneralphysicians
                  img={slid_img2_key}
                  title="Bakantan"
                  btnclick="rend_art"
                  count="34/135"
                  ruppies="price"
                  ethvalue="2ETH"
                  highestbid="highest Bid"
                  ethvalue2="0.3ETH"
                  imgsml={trending_headimg}
                />
                <Propsgeneralphysicians
                  img={slid_img3_key}
                  title="Hairy Harry"
                  btnclick="Mettoz"
                  yellowimg={yello}
                  count="9/75"
                  ruppies="price"
                  ethvalue="5ETH"
                  highestbid="highest Bid"
                  ethvalue2="3.1ETH"
                  imgsml={trending_headimg}
                />
                <Propsgeneralphysicians
                  img={slid_img4_key}
                  title="Masked Man"
                  btnclick="ridsect"
                  count="15/45"
                  ruppies="price"
                  ethvalue="2ETH"
                  highestbid="highest Bid"
                  ethvalue2="0.3ETH"
                  imgsml={trending_headimg}
                />
                <Propsgeneralphysicians
                  img={slid_img5_key}
                  title="Hairy Harry"
                  btnclick="Mettoz"
                  yellowimg2={yello}
                  count="9/75"
                  ruppies="price"
                  ethvalue="5ETH"
                  highestbid="highest Bid"
                  ethvalue2="3.1ETH"
                  imgsml={trending_headimg}
                />
              </Slider>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Generalphysicians;
