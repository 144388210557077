import React from 'react'
import { Container ,Row ,Col} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord ,faTelegram,faTwitter,faYoutube} from '@fortawesome/free-brands-svg-icons';
import { faArrowRightToBracket, faCamera } from '@fortawesome/free-solid-svg-icons';
import gMail from "../../assets/images/twitter1.png";
import youtude from "../../assets/images/youtube.png";
import telegram from "../../assets/images/telegram.png";
import facebook from "../../assets/images/facebook1.png";

import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import "./Footer2.css";
function Footer2() {
  return (
    <footer id='footer'>
        <div className="footer_top">
           <Container>
            <Row>
              <Col md={6}>
                  <div className="left_foot_holder">
                    <h4 className='get_lastest_htag'>Get the latest tenant news</h4>
                    <div className='foot_input_hold'>
                        <Form className='foot_requestinformation_form position-relative'>
                                <Form.Group  controlId="exampleForm.ControlInput1">
                                    <Form.Control type="email" className='foot_requestinformation_input_area' placeholder="Please enter your email" />
                                </Form.Group> 
                                <div className="btn_holder">
                                  <Button className='btn_foot'>sign up</Button>
                                </div>
                        </Form>
                    </div>
                  </div>
              </Col>
              <Col md={6}>
                  <div className="right_foot_holder">
                    <h4 className='join_elumnt_htag'>join tenant community</h4>
                    <div className="ul_li_structured_holder">
                       <ul>
                        <li><Link to="/"className='icon_hovering'><span className="foot_icons"><img width={40} src={gMail}  /></span></Link></li>
                        <li><Link to="/"className='icon_hovering'><span className="foot_icons"><img width={40} src={youtude}  /></span></Link></li>
                        <li><Link to="/"className='icon_hovering'><span className="foot_icons"><img width={40} src={facebook}  /></span></Link></li>

                        <li><Link to="/"className='icon_hovering'><span className="foot_icons"><img width={40} src={telegram}  /></span></Link></li>
                       </ul>
                    </div>
                  </div>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12}>
                <div className="holder_market_tag">
                  <h4 className='elumnt_text'>Sales and Lettings</h4>
                  <ul>
                    <li><Link to="/"className='foot_links'> Sell my house</Link></li>
                    <li><Link to="/"className='foot_links'> Search property for sale</Link></li>
                    <li><Link to="/"className='foot_links'> Letting agents for landlords</Link></li>
                    <li><Link to="/"className='foot_links'> Property to rent</Link></li>
                    <li><Link to="/"className='foot_links'> Land and new homes</Link></li>
                    <li><Link to="/"className='foot_links'> Download the app</Link></li>
                  </ul>
                </div>
              </Col>
              <Col  md={4} sm={6}>
                <div className="holder_market_tag">
                <h4 className='marketheading'>Marketplace</h4>
                  <ul>
                    <li><Link to="/"className='foot_links'> Meet our estate agents</Link></li>
                    <li><Link to="/"className='foot_links'> How much is my house worth</Link></li>
                    <li><Link to="/"className='foot_links'> Property guides</Link></li>
                    <li><Link to="/"className='foot_links'> Mortgage deals</Link></li>
                    <li><Link to="/"className='foot_links'> Blog</Link></li>
                    <li><Link to="/"className='foot_links'> Sitemap</Link></li>
                  </ul>
                </div>
              </Col>
              <Col  md={4} sm={6}>
                <div className="holder_Ecossystem_tag">
                  <h4 className='elumnt_text'>Help and contacts</h4>
                  <ul>
                    <li><Link to="/"className='foot_links'> Contact us</Link></li>
                    <li><Link to="/"className='foot_links'> Login</Link></li>
                    <li><Link to="/"className='foot_links'> Terms & conditions</Link></li>
                    <li><Link to="/"className='foot_links'> FAQs</Link></li>
                    <li><Link to="/"className='foot_links'> Privacy</Link></li>
                    <li><Link to="/"className='foot_links'> Cookies </Link></li>
                  </ul>
                </div>
              </Col>
            </Row>
           </Container>
        </div>
        <div className="footer_bottom">
          <Container>
            <div className="holder_bottom_foot d-flex justify-content-center">
              <div className="c_style">
                <span className='c_form'>c</span>
              </div> 
              <h4>Living links,LLC. All Rights Reserved</h4>
            </div>
          </Container>
        </div>
    </footer>
  )
}

export default Footer2