import React from 'react'
import {Container ,Row,Col, Navbar, Nav,} from "react-bootstrap";
import "./Aboutbanner.css";
function Aboutbanner() {
  return (
    <div className="aboutbanner_holder">
        <Container>
            <div className="aboutbanner_hold">
                <h2 className='h_tag_gettoknowus'>get to know us</h2>
                <p className='p_tag_gettoknowus'>Make a persentaion about you, your agency or your business </p>
            </div>
        </Container>
    </div>
  )
}

export default Aboutbanner