import React from 'react'
import banner_key from "../../assets/images/banner.jpg";
import video from "../../assets/videos/homevideo.mov.mp4";

import bn_cover_key from "../../assets/images/bn_curve.png";
import men_childcolor_key from "../../assets/images/men_childcolor.png";
import men_color_key from "../../assets/images/men_color.png";
import men_starcolor_key from "../../assets/images/men_starcolor.png";
import men_logo_key from "../../assets/images/men_logo.png";
import men2child_key from "../../assets/images/men2child.png";
import men_star_key from "../../assets/images/men_star.png";
import Slider from "react-slick"; 
import Button from 'react-bootstrap/Button';
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import "./Banner.css";
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
function Banner() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        // autoplay:true,
        slidesToScroll: 1,};
  return (
    <div className="banner_holder position-relative">
<Container fluid className='m-0 p-0' >
  <div className='overlay'  >
    <video style={{ width: "100%", height: "100%", objectFit: "cover" }} src={video} autoPlay loop muted/>
  </div>
</Container>
          {/* <Slider {...settings}>
                <div className="slider-img-head1">
                 <img src={banner_key} alt="" />
                </div>
                <div class="slider-img-head1">
                <img  src={banner_key} alt="" />
                </div>
                <div class="slider-img-head1">
                <img class="img-fluid" src={banner_key} alt="" />
                </div>
         </Slider> */}
         {/* <div className="banner_shape">
            <img  src={bn_cover_key} alt="" />
        </div> */}
        
        <Container fluid>
          <div className="text_holder mx-4">
              <h2 className='teleheatth text-center'>Letting services all over the UK</h2>
              <p className='p_tag text-center w-70 fw-bold'>Connecting You to Quality Living</p>
              
              {/* <Link to='/'className='oncallremote'>on-Call,remote property maneger is allways in</Link>
              <button className='btn_talk_to_d'>Talk to a maneger</button> */}
          </div>
          <div className="useclss_for_margin"></div>
          <div className="main_men_imgholder">
            <ul>
              <li><Link to="/" className='men_links'><div className="men_img_holder">  <img class="img-fluid" src={men_logo_key} alt="" /></div><div className="men_img_holder2"><img class="img-fluid" src={men_color_key} alt="" /></div><span className='value_lating'>50,000 Plus User</span> </Link></li>
              <li><Link to="/" className='men_links'><div className="men_img_holder">  <img class="img-fluid" src={men_star_key} alt="" /></div><div className="men_img_holder2"><img class="img-fluid" src={men_starcolor_key} alt="" /></div><span className='value_lating'>No Insurance Requried</span></Link></li>
              <li><Link to="/" className='men_links'><div className="men_img_holder">  <img class="img-fluid" src={men2child_key} alt="" /></div><div className="men_img_holder2"><img class="img-fluid" src={men_childcolor_key} alt="" /></div><span className='value_lating'>4.9 Rated Facebook & Google</span></Link></li>
            </ul>
          </div>
        </Container>
    </div>
  )
}

export default Banner