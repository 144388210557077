import React from 'react'
import "./Aboutbtnmaker.css";

function Aboutbtnmaker() {
  return (
    <div className="aboutbtnmaker_holder">
          <div className="animationbtn_holder">
                <button className='btn'>
                   <span className='btn_spn'>button</span>
                </button>
            </div>
    </div>
  )
}

export default Aboutbtnmaker