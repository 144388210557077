import React, { useState, useEffect, useRef } from 'react'
import {Container ,Row,Col,} from "react-bootstrap";
import Progressbar from './Progressbar';
import "./Abouskillsandexperience.css";
import { useInViewport } from 'react-in-viewport';
function Abouskillsandexperience(props) {
  const myRef = useRef();
  const { inViewport} = useInViewport( myRef, props );
  return (
    <div className="abouskillsandexperience_holder">
        <Container>
            <Row>
                <Col sm={6}>
                    <div className="left_content_aboutskills">
                        <h2 className='h_tag_left_content_aboutskills'>what you,re best art</h2>
                        <p className='p_tag_left_content_aboutskills'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                             tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                              quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan 
                            et iusto odio dignissim qui blandit praesent luptatum zzril</p>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="right_content_aboutskills" ref={myRef}>
                    <h2 className='h_tag_right_content_aboutskills'>SKILLS &amp; EXPERIENCE</h2>
                    { 
                        inViewport ?
                        <>
                            <h2 className='progress_htag'>Photoshop &amp; Illustrator</h2>
                            <Progressbar progressValue = {85} inView= {inViewport} />
                            <h2 className='progress_htag'>Javascript &amp; PHP</h2>
                            <Progressbar progressValue = {60} inView= {inViewport} />
                            <h2 className='progress_htag'>Digital Branding</h2>
                            <Progressbar progressValue = {95} inView= {inViewport}/>
                        </>
                        : ''
                    }
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Abouskillsandexperience