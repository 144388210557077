import React, {useRef, useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import logooo_key from "../../assets/images/logooo.png";
import panda_key from "../../assets/images/panda.png";
import light_key from "../../assets/images/light.png";
import { Waypoint } from 'react-waypoint';
import AOS from "aos";
import $ from 'jquery';
import "aos/dist/aos.css";
import "./Aboutanimation.css";
function Aboutanimation() {
  const [addClass, setAddClass] = useState(false)
  const _handleWaypointEnter = () => {
    setAddClass(true)
    // $('.daimont_cls, .testt').addClass('active');
  }
  
  const _handleWaypointLeave = () => {
    setAddClass(false)
    // $('.daimont_cls, .testt').removeClass('active');
  }

  return (
    <div className="aboutanimation_holder">
          <div className={`daimont_cls ${addClass ? "active" : ""}`}></div>
          <div className={`testt ${addClass ? "active": ""}`}></div>
        <Container>
        <Waypoint onEnter={_handleWaypointEnter} onLeave={_handleWaypointLeave}>
            <div className="aboutanimation_hold">
                    <div className="pand_img_holdleft">
                        <img class="img-fluid" src={panda_key} alt="" />
                    </div>
                    <div className="pand_img_hold">
                       <img class="img-fluid" src={logooo_key} alt="" />
                    </div>
                    <div className="pand_img_holdleft">
                        <img class="img-fluid" src={light_key} alt="" />
                    </div>
            </div>


          </Waypoint>
        </Container>
    </div>
  )
}

export default Aboutanimation