import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar,faAngleRight} from '@fortawesome/free-solid-svg-icons';
import './Generalphysicians.css';

const Propsgeneralphysicians =(props)=> {
  return (
    <div class="trendingnftslider-mg position-relative">
    <div class=" trendingnft_slider_img">
         <img class="img-fluid" src={props.img} alt="" />
     </div>
     <div className="trendin_heading_detail">
       <Link to="/" className='btn_trending_slider'>{props.btnclick}</Link>
          <div className='hold_by position-relative d-flex'>
            <h3>{props.title}</h3>
            <div class="trending_yellow_img"><img class="img-fluid" src={props.yellowimg} alt="" /></div>
            {/* <div className="trending_pointscalculate">{props.count}</div> */}
          </div>
     </div>
    
</div>
    
  )
}

export default Propsgeneralphysicians