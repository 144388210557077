import React, { useRef } from 'react'
import {Container ,Row,Col,} from "react-bootstrap";
import { useInViewport } from 'react-in-viewport';
import Countingprops from './Countingprops';
import "./Aboutcountingnum.css";

function Aboutcountingnum(props) {
    const myRef = useRef();
    const { inViewport} = useInViewport( myRef, props );
  return (
        <div className="counting_num_holder">
            <Container>
            <div className="view_port_holder" ref={myRef}>
                { 
                    inViewport ?
                    <>
                    <Row>
                        <Col sm={3}>
                           <Countingprops countValue = {128} inView= {inViewport} />
                           <h2 className='products_name'>cups of coffee</h2>
                        </Col>
                        <Col sm={3}>
                           <Countingprops countValue = {26} inView= {inViewport} />
                           <h2 className='products_name'>Team Members</h2>
                        </Col>
                        <Col sm={3}>
                           <Countingprops countValue = {250} inView= {inViewport} />
                           <h2 className='products_name'>Hours Spent</h2>
                        </Col>
                        <Col sm={3}>
                           <Countingprops countValue = {128} inView= {inViewport} />
                           <h2 className='products_name'>Cups of Coffee</h2>
                        </Col>
                    </Row>
                    </>
                : ''
                }
            </div>
            </Container>
        </div>
  )
}

export default Aboutcountingnum