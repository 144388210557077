import React from 'react'
import Header  from '../Header/Header'
import Aboutbanner from '../Aboutbanner/Aboutbanner'
import Aboushowtheworld from '../Aboushowtheworld/Aboushowtheworld'
import Whatyougetwithcurehotline from '../Whatyougetwithcurehotline/Whatyougetwithcurehotline'
import Aboutslider from '../Aboutslider/Aboutslider'
import Abouskillsandexperience from '../Abouskillsandexperience/Abouskillsandexperience'
import Aboutcountingnum from '../Aboutcountingnum/Aboutcountingnum'
import Aboutanimation from '../Aboutanimation/Aboutanimation'
import Aboutmapmaker from '../Aboutmapmaker/Aboutmapmaker'
import Aboutbtnmaker from '../Aboutbtnmaker/Aboutbtnmaker'
import Footer from '../Footer/Footer'
function Aboutus() {
  return (
    <>
      <Header/>
      <Aboutbanner/>
      <Aboushowtheworld/>
      <Whatyougetwithcurehotline/>
      <Aboutslider/>
      <Abouskillsandexperience/>
      <Aboutcountingnum/>
      <Aboutanimation/>
      <Aboutbtnmaker/>
      <Aboutmapmaker/>
      <Footer/>
    </>
  )
}

export default Aboutus