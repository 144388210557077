
import React, { useState, useEffect, useRef  } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import "./Abouskillsandexperience.css";
const Progressbar =(props)=> {
  const [ progress, setProgress ] = useState(0)
  useEffect(() => {
      if(progress < props.progressValue ){
          setTimeout(()=> {
              setProgress( prevVal => prevVal+1)
          },30)
      }
  }, [progress])
  
  useEffect(()=> {
    if(props.inView){
      setProgress(0)
    }
  }, [props.inView])


  return (
    <div className="progresbar_props_holder"  >
       <div className='progressbar_holder'>
              <ProgressBar className="right" variant="success" now={progress} label={<span className='progressTag'>{`${progress}%`}</span> }/>
        </div>
    </div>
  )
}

export default Progressbar