import React from 'react'
import {Container,Row,Col} from "react-bootstrap";
import three_text_key from "../../assets/images/three_text.png";
import MyVideo from '../../assets/images/videotag.mp4'
import { Link } from "react-router-dom";
import "./Curehotline_content.css";

function Curehotline_content() {
  return (
    <div className="curehotlinecontent_holder">
      <Container>
        <div className="curehotlinecontent_hold">
          <h2 className="curecontent_htag">
            Living Links - what we do & how we do it
          </h2>
          
          <p className="ptag_2 ">
            We work closely with landlords and property managers to ensure that
            our listings are accurate, up-to-date, and include all the essential
            details you need to make an informed decision. We provide virtual
            tours and high-resolution photos for most properties so you can get
            a feel for the space before scheduling a viewing. Our platform facilitates secure and direct
            communication between tenants and landlords, helping to streamline
            the rental process and reduce any potential misunderstandings.
            Our dedicated support team is always on hand
            to assist with any queries, whether it’s navigating the site or
            understanding rental agreements.
          </p>
        </div>
        <Row className="d-none">
          <Col sm={6}>
            <div className="video_holder">
              <div className="three_content_imghold">
                <img class="img-fluid" src={three_text_key} alt="" />
              </div>
              <div className="video_holder">
                <video className="video_styling" controls>
                  <source src={MyVideo} type="video/mp4" />
                  <source src="movie.ogg" type="video/ogg" />
                </video>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className="content_holder">
              <h2 className="content_htag">
                curehotline - what we do &amp;
                <span className="spn_howtodo">how we do it</span>
              </h2>
              <p className="content_ptag">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima,
                tempora eveniet hic praesentium debitis nulla perferendis dicta
                numquam dolor. Vero quis illum ab cumque reiciendis repudiandae,
                quibusdam molestias totam sed.
              </p>
            </div>
            <div className="content_num">
              <div className="one_style">
                <ul>
                  <li>
                    <div className="onestyle_ul_left">
                      <span className="one">1</span>
                    </div>
                    <div className="onestyle_ul_right">
                      <Link to="/" className="counting_links"></Link>
                      <Link to="/" className="counting_links">
                        <span className="spn_text_madicalcontidion">
                          Your Madical Condition
                        </span>
                      </Link>
                      <p className="ptag_counting">
                        Answer the question which cover your symptoms, and
                        pharmacy information
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="onestyle_ul_left">
                      <span className="one">2</span>
                    </div>
                    <div className="onestyle_ul_right">
                      <Link to="/" className="counting_links"></Link>
                      <Link to="/" className="counting_links">
                        <span className="spn_text_madicalcontidion">
                          Fill and Aubmint Consultation Form
                        </span>
                      </Link>
                      <p className="ptag_counting">
                        Certified doctors will review your consultation request
                        in 1 to 2 hours
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="onestyle_ul_left">
                      <span className="one">3</span>
                    </div>
                    <div className="onestyle_ul_right">
                      <Link to="/" className="counting_links"></Link>
                      <Link to="/" className="counting_links">
                        <span className="spn_text_madicalcontidion">
                          Get Madicines from your Pharmacy
                        </span>
                      </Link>
                      <p className="ptag_counting">
                        Ones approved, the prescription will be sent to your
                        pharmacy in 1-4 hours
                      </p>
                    </div>
                  </li>
                  {/* <li> <Link to="/" className='counting_links'><span className='one'>2</span></Link><Link to="/"className='counting_links'><span className='spn_text_madicalcontidion'>Fill and Aubmint Consultation Form</span></Link><p className='ptag_counting'>Certified doctors will review  your consultation request in 1 to 2 hours</p></li> */}
                  {/* <li> <Link to="/" className='counting_links'><span className='one'>3</span></Link><Link to="/"className='counting_links'><span className='spn_text_madicalcontidion'>Get Madicines from your Pharmacy</span></Link><p className='ptag_counting'>Ones approved, the prescription will be sent to your pharmacy in 1-4 hours</p></li> */}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Curehotline_content